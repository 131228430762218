<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.625 14.625H9.75L6 18.375H13.875L17.625 14.625Z" fill="#CB0000"/>
  <path d="M15.9133 8.08667L3 21M17.7581 14.5433H9.45666M7.24295 18.2329H13.4573C13.6829 18.2329 13.7957 18.2329 13.9018 18.2074C13.9959 18.1848 14.0859 18.1475 14.1684 18.0969C14.2615 18.0399 14.3413 17.9601 14.5008 17.8006L19.1417 13.1598C19.3621 12.9393 19.4723 12.8291 19.561 12.7316C21.4797 10.6207 21.4797 7.39735 19.561 5.28653C19.4723 5.18896 19.3621 5.07875 19.1417 4.85834C18.9212 4.63792 18.811 4.52772 18.7135 4.43902C16.6026 2.52033 13.3793 2.52033 11.2684 4.43902C11.1709 4.52772 11.0607 4.63792 10.8402 4.85833L6.1994 9.49918C6.03987 9.6587 5.96011 9.73847 5.90306 9.83155C5.85249 9.91408 5.81522 10.0041 5.79263 10.0982C5.76714 10.2043 5.76714 10.3171 5.76714 10.5427V16.757C5.76714 17.2736 5.76714 17.5319 5.86768 17.7292C5.95611 17.9028 6.09721 18.0439 6.27077 18.1323C6.46808 18.2329 6.72637 18.2329 7.24295 18.2329Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: 'QIconQuail',
};
</script>