<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    width="24"
    height="24"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
  <g clip-path="url(#clip0_110_6)">
  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_110_6)"/>
  <path d="M17.8406 6.90468L15.6984 17.7094C15.6984 17.7094 15.3984 18.4594 14.5734 18.0984L9.62811 14.3062L7.82811 13.4391L4.79999 12.4219C4.79999 12.4219 4.33592 12.2578 4.28905 11.8969C4.24217 11.5359 4.81405 11.3437 4.81405 11.3437L16.8515 6.62343C16.8515 6.61874 17.8406 6.1828 17.8406 6.90468Z" fill="white"/>
  <path d="M9.24375 17.5875C9.24375 17.5875 9.09844 17.5734 8.92031 17.0062C8.74219 16.439 7.82812 13.439 7.82812 13.439L15.0984 8.82185C15.0984 8.82185 15.5203 8.56873 15.5016 8.82185C15.5016 8.82185 15.5766 8.86873 15.3516 9.07498C15.1266 9.28591 9.64219 14.2172 9.64219 14.2172" fill="#D2E4F0"/>
  <path d="M11.5219 15.7594L9.56716 17.5453C9.56716 17.5453 9.41248 17.6625 9.24841 17.5875L9.62341 14.2734" fill="#B5CFE4"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_110_6" x1="12" y1="0" x2="12" y2="23.9124" gradientUnits="userSpaceOnUse">
  <stop stop-color="#41BCE7"/>
  <stop offset="1" stop-color="#22A6DC"/>
  </linearGradient>
  <clipPath id="clip0_110_6">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
<script>
export default {
  name: 'QIconColorTelegram',
};
</script>