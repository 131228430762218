<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 5L10 5M21 19L10 19M21 12L10 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.57548 7.00005V4.14405L2.80748 4.54005V3.52605L3.76148 2.99805H4.77548V7.00005H3.57548Z" fill="currentColor"/>
<path d="M2.54175 14V13.184C2.67775 13.112 2.82175 13.024 2.97375 12.92C3.12575 12.812 3.27175 12.694 3.41175 12.566C3.55575 12.434 3.68375 12.3 3.79575 12.164C3.91175 12.028 4.00375 11.892 4.07175 11.756C4.13975 11.62 4.17375 11.494 4.17375 11.378C4.17375 11.222 4.14175 11.102 4.07775 11.018C4.01375 10.934 3.93575 10.878 3.84375 10.85C3.75575 10.818 3.67175 10.802 3.59175 10.802C3.41975 10.802 3.25375 10.846 3.09375 10.934C2.93775 11.022 2.76375 11.142 2.57175 11.294V10.31C2.74375 10.206 2.94375 10.114 3.17175 10.034C3.39975 9.95005 3.67375 9.90805 3.99375 9.90805C4.27775 9.90805 4.52375 9.95405 4.73175 10.046C4.93975 10.138 5.09975 10.278 5.21175 10.466C5.32375 10.654 5.37975 10.894 5.37975 11.186C5.37975 11.378 5.34575 11.554 5.27775 11.714C5.20975 11.87 5.11775 12.014 5.00175 12.146C4.88575 12.274 4.75775 12.392 4.61775 12.5C4.47775 12.608 4.33575 12.708 4.19175 12.8C4.04775 12.892 3.91175 12.982 3.78375 13.07L3.56775 12.92H5.46375V14H2.54175Z" fill="currentColor"/>
<path d="M3.82727 21.048C3.61127 21.048 3.39927 21.016 3.19127 20.952C2.98727 20.884 2.79327 20.804 2.60927 20.712V19.722C2.80927 19.842 3.00327 19.95 3.19127 20.046C3.37927 20.138 3.57727 20.184 3.78527 20.184C3.96127 20.184 4.10327 20.154 4.21127 20.094C4.31927 20.03 4.37327 19.938 4.37327 19.818C4.37327 19.694 4.33327 19.592 4.25327 19.512C4.17727 19.428 4.01527 19.386 3.76727 19.386H3.11327V18.522H3.77927C3.93127 18.522 4.03927 18.488 4.10327 18.42C4.16727 18.348 4.19927 18.272 4.19927 18.192C4.19927 18.092 4.17127 18.018 4.11527 17.97C4.06327 17.918 3.99527 17.882 3.91127 17.862C3.83127 17.842 3.74927 17.832 3.66527 17.832C3.54527 17.832 3.41327 17.848 3.26927 17.88C3.12527 17.908 2.92927 17.98 2.68127 18.096V17.226C2.84127 17.142 3.01527 17.074 3.20327 17.022C3.39527 16.97 3.58727 16.944 3.77927 16.944C3.93527 16.944 4.09727 16.962 4.26527 16.998C4.43727 17.03 4.59527 17.088 4.73927 17.172C4.88727 17.252 5.00727 17.364 5.09927 17.508C5.19127 17.648 5.23727 17.826 5.23727 18.042C5.23727 18.138 5.21927 18.23 5.18327 18.318C5.14727 18.406 5.09927 18.486 5.03927 18.558C4.97927 18.63 4.91127 18.69 4.83527 18.738C4.76327 18.782 4.68927 18.812 4.61327 18.828C4.72927 18.86 4.83527 18.91 4.93127 18.978C5.02727 19.042 5.10927 19.12 5.17727 19.212C5.24927 19.3 5.30327 19.402 5.33927 19.518C5.37927 19.63 5.39927 19.75 5.39927 19.878C5.39927 20.118 5.34927 20.314 5.24927 20.466C5.15327 20.618 5.02527 20.736 4.86527 20.82C4.70927 20.904 4.53927 20.964 4.35527 21C4.17527 21.032 3.99927 21.048 3.82727 21.048Z" fill="currentColor"/>
</svg>
</template>
<script>
export default {
  name: 'QIconOrderedList',
};
</script>