<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_366_40)">
  <mask id="mask0_366_40" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <path d="M24 0H0V24H24V0Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_366_40)">
  <path d="M24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12Z" fill="#2B90D9"/>
  </g>
  <path d="M18.5075 13.7991C18.3077 14.8271 16.7178 15.9522 14.8918 16.1702C13.9396 16.2838 13.0021 16.3882 12.0024 16.3424C10.3676 16.2675 9.07753 15.9522 9.07753 15.9522C9.07753 16.1113 9.08735 16.2629 9.10698 16.4046C9.31952 18.018 10.7068 18.1147 12.021 18.1597C13.3474 18.2051 14.5284 17.8327 14.5284 17.8327L14.5829 19.0318C14.5829 19.0318 13.6551 19.53 12.0024 19.6217C11.0911 19.6717 9.95951 19.5987 8.64153 19.2499C5.78304 18.4933 5.29145 15.4463 5.21623 12.3546C5.1933 11.4367 5.20743 10.5711 5.20743 9.84716C5.20743 6.68576 7.27879 5.7591 7.27879 5.7591C8.32322 5.27944 10.1154 5.07773 11.9785 5.0625H12.0243C13.8874 5.07773 15.6807 5.27944 16.7251 5.7591C16.7251 5.7591 18.7964 6.68576 18.7964 9.84716C18.7964 9.84716 18.8223 12.1797 18.5075 13.7991Z" fill="white"/>
  <path d="M16.3531 10.0924V13.9204H14.8366V10.2049C14.8366 9.42173 14.5071 9.0242 13.8479 9.0242C13.1191 9.0242 12.7539 9.49577 12.7539 10.4282V12.4619H11.2463V10.4282C11.2463 9.49577 10.8809 9.0242 10.1521 9.0242C9.49297 9.0242 9.16343 9.42173 9.16343 10.2049V13.9204H7.64688V10.0924C7.64688 9.31008 7.84608 8.68839 8.2462 8.22843C8.65881 7.76848 9.19916 7.5327 9.86993 7.5327C10.646 7.5327 11.2337 7.83098 11.6223 8.42763L12 9.06087L12.3778 8.42763C12.7663 7.83098 13.354 7.5327 14.1302 7.5327C14.8009 7.5327 15.3412 7.76848 15.7539 8.22843C16.1539 8.68839 16.3531 9.31008 16.3531 10.0924Z" fill="#2B90D9"/>
  </g>
  <defs>
  <clipPath id="clip0_366_40">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
<script>
export default {
  name: 'QIconColorMastodon',
};
</script>
