<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_377_86)">
  <path d="M0.489643 15.4507C1.97335 20.3947 6.56217 24 11.9923 24C18.6239 24 24 18.6274 24 12C24 5.37262 18.6239 0 11.9923 0C5.62895 0 0.421724 4.94738 0.0104599 11.2028C0.78871 12.5066 1.09078 13.3132 0.488892 15.4507H0.489643Z" fill="url(#paint0_linear_377_86)"/>
  <path d="M11.3735 8.99439L11.3746 9.05364L8.4365 13.3193C7.96069 13.2975 7.48339 13.3811 7.0301 13.5675C6.83277 13.6477 6.64438 13.7482 6.46798 13.8675L0.0157134 11.2099C0.0157134 11.2099 -0.133633 13.6646 0.488517 15.4939L5.04995 17.3749C5.27509 18.3979 5.98055 19.2949 7.01696 19.7261C7.83234 20.0647 8.74885 20.066 9.5652 19.7298C10.3816 19.3936 11.031 18.7473 11.3709 17.9329C11.5522 17.4991 11.6397 17.0319 11.6276 16.5619L15.8276 13.56L15.9308 13.5619C18.4468 13.5619 20.4919 11.5121 20.4919 8.99439C20.4919 6.47664 18.4506 4.43439 15.9308 4.42914C13.4159 4.42914 11.3698 6.47664 11.3698 8.99439H11.3735ZM10.6681 17.6381C10.1225 18.9506 8.61699 19.5683 7.30852 19.0234C6.72788 18.7795 6.25724 18.3308 5.98617 17.7626L7.471 18.3776C7.70038 18.4731 7.94632 18.5224 8.19478 18.5228C8.44323 18.5232 8.68933 18.4746 8.91901 18.3799C9.14869 18.2852 9.35745 18.1463 9.53336 17.9709C9.70927 17.7956 9.84888 17.5873 9.94422 17.358C10.1373 16.8947 10.1384 16.3738 9.94739 15.9096C9.75637 15.4455 9.3888 15.0761 8.92544 14.8826L7.38695 14.247C7.97908 14.022 8.65226 14.0145 9.28192 14.2759C9.91983 14.5395 10.4076 15.036 10.6688 15.6709C10.93 16.3058 10.9285 17.0059 10.6651 17.6381M15.9349 12.0375C15.1287 12.0353 14.3562 11.7139 13.7867 11.1437C13.2171 10.5734 12.8969 9.80084 12.8962 8.99514C12.8971 8.18957 13.2174 7.41717 13.7869 6.84709C14.3565 6.27701 15.1289 5.9557 15.9349 5.95351C16.7412 5.9555 17.5137 6.27672 18.0834 6.84683C18.6531 7.41693 18.9735 8.18944 18.9744 8.99514C18.9737 9.80097 18.6534 10.5737 18.0837 11.1439C17.514 11.7142 16.7413 12.0355 15.9349 12.0375ZM13.658 8.99026C13.6575 8.3849 13.8975 7.8041 14.3253 7.37552C14.7531 6.94694 15.3337 6.70563 15.9394 6.70464C17.1984 6.70464 18.2224 7.72839 18.2224 8.99026C18.2227 9.59576 17.9824 10.1766 17.5543 10.6051C17.1262 11.0335 16.5453 11.2745 15.9394 11.2751C15.3338 11.2742 14.7532 11.0331 14.3253 10.6047C13.8975 10.1762 13.6577 9.59556 13.658 8.99026Z" fill="white"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_377_86" x1="12.0052" y1="0" x2="12.0052" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#111D2E"/>
  <stop offset="0.41" stop-color="#0A1B48"/>
  <stop offset="0.74" stop-color="#144B7E"/>
  <stop offset="1" stop-color="#1387B8"/>
  </linearGradient>
  <clipPath id="clip0_377_86">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
</svg>
</template>
<script>
export default {
  name: 'QIconColorSteam',
};
</script>