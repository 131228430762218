<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_23)">
  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#1686B0"/>
  <path d="M8.7 17.45H6.4V10.05H8.7V17.45ZM7.45 9.1C6.6 9.1 6.05 8.55 6.05 7.8C6.05 7.05 6.6 6.5 7.45 6.5C8.3 6.5 8.85 7.05 8.85 7.8C8.85 8.55 8.35 9.1 7.45 9.1ZM17.95 17.45H15.3V13.6C15.3 12.6 14.9 11.9 14 11.9C13.3 11.9 12.95 12.35 12.75 12.8C12.7 12.95 12.7 13.2 12.7 13.4V17.4H10.1C10.1 17.4 10.15 10.6 10.1 10H12.7V11.15C12.85 10.65 13.7 9.9 15 9.9C16.65 9.9 17.95 10.95 17.95 13.25V17.45Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_1_23">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
<script>
export default {
  name: 'QIconColorLinkedin',
};
</script>