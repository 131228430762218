<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    width="24"
    height="24"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <g clip-path="url(#clip0_110_2)">
    <path d="M24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12Z" fill="#F26522"/>
    <path d="M7.57501 17.5266C6.67033 17.5266 5.93907 16.7953 5.93907 15.8906C5.93907 14.9859 6.67033 14.2547 7.57501 14.2547C8.4797 14.2547 9.21095 14.9859 9.21095 15.8906C9.21095 16.7906 8.4797 17.5266 7.57501 17.5266ZM11.4844 17.5266C11.4516 14.4797 8.98126 12.0094 5.93439 11.9766V9.55311C10.3266 9.58593 13.875 13.1391 13.9078 17.5266H11.4844ZM15.5906 17.5266C15.5813 14.9484 14.5734 12.5203 12.75 10.6969C10.9266 8.87343 8.50782 7.86561 5.93439 7.85624V5.4328C12.6 5.45624 18 10.8609 18.0141 17.5266H15.5906Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_110_2">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'QIconColorFeed',
};
</script>